<template>
  <div class="maintenance-banner flex-inline">
    <div v-if="props.maintenanceSettings.maintenance_message" v-text="props.maintenanceSettings.maintenance_message" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  maintenanceSettings: {
    type: Object as () => {
      maintenance_active: boolean;
      maintenance_message: string;
    },
    default: () => {},
  },
});
</script>

<style lang="scss" scoped>
.maintenance-banner {
  justify-content: center;
  padding: 10px 8px;
  width: 100%;
  min-height: 40px;
  background-color: $color-danger-dark;
  opacity: 1;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
  color: $color-neutral-white;
  a {
    color: $color-neutral-black;
    font-weight: 500;
  }
}
</style>
